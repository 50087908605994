<template>
    <div v-bind="$attrs" v-if="dsRegister.state.isLoaded && dsRegisterAssignments.state.isLoaded && dsRegisterAssignments.data.length > 0" @mouseover="hover=true" @mouseleave="hover=false">
        <OPagedList :dataObject="dsRegisterAssignments" >
            <template #heading>
                <span class="d-flex align-items-baseline w-100 justify-content-between">
                    <div class = "d-flex">
                        <a :href="`/${dsRegister.current.App_ID}?Register_ID=${dsRegister.current.ID}&filtermode=${dsRegister.current.HomeFilterLink}`" class="drag-ignore" v-if="dsRegister.current.HomeFilterLink">{{ dsRegister.current.Name }}</a>
                        <a :href="`/${dsRegister.current.App_ID}?Register_ID=${dsRegister.current.ID}`" v-if="!dsRegister.current.HomeFilterLink" class="drag-ignore">{{ dsRegister.current.Name }}</a>
                        <OActionList :title="[$t('Current Context'), context.name]" markTitle>
                            <template #target = "{ target }">
                                <button 
                                    class="btn btn-link btn-sm drag-ignore dropdown-toggle"  
                                    @click = "(e) => newScopeItem(e)"
                                    :ref="target"
                                >{{ $t("New") }}</button>
                            </template>
                                    
                            <template #default v-if="dsProcesses.state.isLoading">
                                <OActionItem :disabled="true" :text="$t('Loading...')" ></OActionItem>
                            </template>

                            <template #default>
                                <template v-for="item in dsProcesses.data">
                                    <OActionItem :text="$t(item.Name)" v-if="item.CreateNewApp_ID" :href="`/nt/${item.CreateNewApp_ID}?process-id=${item.ID}&Context=${context.id}&Register_ID=${item.Register_ID}`" ></OActionItem>
                                    <OActionItem :text="$t(item.Name)" v-if="!item.CreateNewApp_ID" :href="`/nt/scope-workflow-new?process-id=${item.ID}&Context=${context.id}&Register_ID=${item.Register_ID}`" ></OActionItem>
                                </template>
                            </template>

                            <template #default v-if="dsProcesses.data.length === 0 && !dsProcesses.state.isLoading">
                                <OActionItem :disabled="true" :text="$t('No processes for context')" ></OActionItem>
                            </template>

                        </OActionList>
                    </div>
                    <div v-if="!isMobile">
                        <i class="bi bi-arrows-move" :title="$t('Drag and drop element within marked square')" style="cursor: move;" v-if="hover"></i>
                    </div>
                </span>
            </template>
            <template v-slot="{ row }" >
                <span class="d-flex text-truncate gap-1 drag-ignore">
                    <a :href="getScopeItemDetailLink(row)" v-scope-hover="row.ID" class="d-flex gap-1">
                        <span v-if="row.PlannedStart">{{ utils.formatDate(row.PlannedStart, 'Short Date') }}:</span>
                        <span>{{ row.Title }}</span>
                    </a>
                </span>
            </template>
        </OPagedList>
    </div>
</template>

<script setup>
import { ref, defineProps, computed } from 'vue';
import { utils } from 'o365-utils';
import { OPagedList } from 'o365-data-pagination';
import { context, getUserSession } from 'o365-modules';
import { getOrCreateDataObject } from 'o365-dataobject';
import vScopeHover from 'scope.libraries.vue.directive.scopeItemHover.ts';
import { isMobile } from 'o365.GlobalState.ts';
import { OActionList, OActionItem } from "o365-ui-components" 

const hover = ref(false);
const session = getUserSession();

const props = defineProps({
    registerId: Number
})

const dsRegister = getOrCreateDataObject({
    "id": `dsRegister-${props.registerId}`,
    "isStaticId": true,
    "viewName": "sviw_System_Registers",
    "distinctRows": false,
    "uniqueTable": null,
    "allowUpdate": false,
    "allowInsert": false,
    "allowDelete": false,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Name",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "App_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "MenuGroup_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "NewApp_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Type",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "PrimKey",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "OrderByOptions",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        }
        ,
        {
            "name": "FilterOptions",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "DefaultSortOrder",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "HomeFilterMode",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Filter",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },{
            "name": "HomeFilterLink",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },

        
    ],
    "masterDataObject_ID": null,
    "masterDetailDefinition": [],
    "clientSideHandler": false,
    "maxRecords": 5,
    "dynamicLoading": false,
    "whereClause": null,
    "filterString": null,
    "disableAutoLoad": false,
    "optimisticLocking": false
});
window.dsRegister = dsRegister;


const dsProcesses = getOrCreateDataObject({
    "id": `dsProcesses-${props.registerId}`,
    "isStaticId": true,
    "viewName": "aviw_Scope_ProcessesForLookup",
    "distinctRows": true,
    "uniqueTable": null,
    "allowUpdate": false,
    "allowInsert": false,
    "allowDelete": false,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "EnforceCatAorB",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "HideProcessFromNewWF",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "IsChange",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Name",
            "sortOrder": 1,
            "sortDirection": "asc",
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "RequiresActivity",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },{
            "name": "CreateNewApp_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },{
            "name": "Register_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
    ],
    "masterDataObject_ID": null,
    "masterDetailDefinition": [],
    "clientSideHandler": false,
    "maxRecords": -1,
    "dynamicLoading": false,
    "whereClause": null,
    "filterString": null,
    "disableAutoLoad": false,
    "optimisticLocking": false
});

const dsRegisterAssignments = getOrCreateDataObject({
    "id": `dsRegisterAssignments-${props.registerId}`,
    "isStaticId": true,
    "viewName": "aviw_Scope_MyItems",
    "distinctRows": false,
    "uniqueTable": null,
    "allowUpdate": false,
    "allowInsert": false,
    "allowDelete": false,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Created",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Title",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "DetailApp_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "MobileDetailApp_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "PlannedStart",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        
        
        {
            "name": "Closed",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        }
    ],
    "masterDataObject_ID": null,
    "masterDetailDefinition": [],
    "clientSideHandler": false,
    "maxRecords": 5,
    "dynamicLoading": false,
    "whereClause": null,
    "filterString": null,
    "disableAutoLoad": false,
    "optimisticLocking": false,
    "definitionProc": "astp_Scope_MyItemsDefinition"
});



dsRegisterAssignments.recordSource.sqlStatementParameters = {
    Register_ID: props.registerId
}

dsRegister.recordSource.whereClause = `ID = ${props.registerId}`;

dsRegister.load().then(() => {
    const sortOrder = [];
    
    //sortOrder.push({ "Created": "desc" });
    
    if(dsRegister.current.DefaultSortOrder !== null){
        if(dsRegister.current.DefaultSortOrder.split(" ").length > 1){
            const obj = {};
            obj[dsRegister.current.DefaultSortOrder.split(" ")[0]] = dsRegister.current.DefaultSortOrder.split(" ")[1].toLowerCase();
            sortOrder.push(obj);
        }
        
        sortOrder.push({ "ID": "desc" });
    }

    dsRegisterAssignments.recordSource.setSortOrder(sortOrder);

    if(dsRegister.current.Filter){
        dsRegisterAssignments.recordSource.whereClause = dsRegister.current.Filter.replace("@Omega365Person_ID", session.personId);
        dsRegisterAssignments.load();
    }
});

function newScopeItem(e) {
    dsProcesses.recordSource.whereClause = "AccessOrgUnit_ID = " + context.id + "AND Register_ID = " + props.registerId;
    
    dsProcesses.load();
}

const getScopeItemDetailLink = (row) => {
    if (isMobile.value && row.MobileDetailApp_ID !== null) {
        return `/${row.MobileDetailApp_ID}?ID=${row.ID}`;
    }

    return `/${row.DetailApp_ID}?ID=${row.ID}`;
}
</script>